import { styled } from '@material-ui/core'

export const MasonryThumbnailContainer = styled('div')(
  ({ theme, width, height }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginRight: 2,
    marginLeft: 2,
    overflow: 'hidden',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    // boxShadow: theme.shadows[3],
    width,
    height
  })
)

export const MasonryThumbnailBox = styled('div')(() => ({
  // width: '100%',
  // objectFit: 'cover',
  // height: '100%'
}))

export const MasonryImage = styled('img')(({ theme }) => ({
  width: '100%',
  filter: 'brightness(0.8);',
  '&:hover': {
    filter: 'brightness(1);'
  },
  transitionProperty: 'filter',
  transitionDuration: theme.transitions.duration.standard,
  transitionTimingFunction: theme.transitions.easing.easeInOut
}))

export const MasonryCaption = styled('div')(({ theme }) => ({
  cursor: 'default',
  position: 'absolute',
  bottom: theme.spacing(0.5),
  left: theme.spacing(0.5),
  backgroundColor: 'rgba(0,0,0,0.5)',
  color: 'white',
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: 3,
  paddingRight: 3,
  maxWidth: 133,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'flex',
  flexDirection: 'column'
}))

export const PromptMasonryImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  filter: 'brightness(0.8)',
  '&:hover': {
    filter: 'brightness(1)'
  },
  transitionProperty: 'filter',
  transitionDuration: theme.transitions.duration.standard,
  transitionTimingFunction: theme.transitions.easing.easeInOut
}))
